import React, { useState } from 'react'
import s from './sidebar.module.scss'
import {
  IconPlans,
  IconGraph,
  IconStackedPills,
  IconBigPillLittlePill,
  IconColumns,
  IconTrendingUp,
  IconTriangleRight,
} from 'components/core/icons/Icons'
import { Link } from 'components/utils/Router'
import Transition from 'components/utils/Transition'

const Sidebar = ({ route, setRoute, bookmarks, setBookmarks, plan }) => {
  const menuItems = [
    {
      name: 'Course plans',
      icon: <IconPlans></IconPlans>,
      route: '/',
      exact: true,
    },
    {
      name: 'My progress',
      icon: <IconGraph></IconGraph>,
      route: 'my-progress',
    },
  ]

  const renderMenu = menuItems.map((item, i) => {
    return (
      <li key={i}>
        <Link to={item.route} exact={item.exact ? '' : null}>
          {item.icon} {item.name}
        </Link>
      </li>
    )
  })

  // nav menu

  const browsingItems = [
    {
      name: 'Courses',
      icon: <IconStackedPills></IconStackedPills>,
      route: 'courses',
    },
    {
      name: 'Majors and minors',
      icon: <IconBigPillLittlePill></IconBigPillLittlePill>,
      route: 'majors-and-minors',
    },
    { name: 'Units', icon: <IconColumns></IconColumns>, route: 'units' },
    {
      name: 'Career pathways',
      icon: <IconTrendingUp></IconTrendingUp>,
      route: 'career-pathways',
    },
  ]

  const renderBrowsingMenu = browsingItems.map((item, i) => {
    return (
      <li key={i}>
        <Link to={item.route}>
          {item.icon} {item.name}
        </Link>
      </li>
    )
  })

  // bookmarks

  // const bookmark = {
  //   add: (type, value) => {
  //     const tempBookmarks = { ...bookmarks }
  //     tempBookmarks[type].push(value)
  //     setBookmarks(tempBookmarks)
  //   },
  //   remove: (type, value) => {
  //     const tempBookmarks = { ...bookmarks }
  //     tempBookmarks[type] = tempBookmarks[type].filter(
  //       (item) => item.value !== value
  //     )
  //     setBookmarks(tempBookmarks)
  //   },
  // }

  const renderBookmarks = (type) =>
    bookmarks[type].map((item, i) => {
      return (
        <li
          key={i}
          onClick={() => setRoute(item.value)}
          className={route === item.value ? s.selected : ''}>
          {item.name}
        </li>
      )
    })

  const [expanded, setExpanded] = useState({
    courses: false,
    majorsMinors: false,
    units: false,
    careerPathways: false,
  })

  const bookmarkCategories = [
    { name: 'Courses', key: 'courses' },
    { name: 'Majors and minors', key: 'majorsMinors' },
    { name: 'Units', key: 'units' },
    { name: 'Career pathways', key: 'careerPathways' },
  ]

  const renderBookmarkCategories = () =>
    bookmarkCategories.map((item, i) => {
      return (
        <li key={i} className={expanded[item.key] ? s.expanded : ''}>
          <h2
            onClick={() => {
              setExpanded((f) => {
                return { ...f, [item.key]: !expanded[item.key] }
              })
            }}>
            <span
              className={`${s.triangle} ${
                bookmarks[item.key].length ? s.notEmpty : ''
              }`}>
              <IconTriangleRight></IconTriangleRight>
            </span>
            {item.name}
          </h2>

          <ul className={s.sidebarMenu}>{renderBookmarks(item.key)}</ul>
        </li>
      )
    })

  // render

  return (
    <div className={s.sidebar}>
      <Transition watch={plan.highlight}>
        <div className={s.highlight}></div>
      </Transition>
      {/* menu */}
      <div>
        <ul className={s.sidebarMenu}>{renderMenu}</ul>
      </div>

      {/* browsing menu */}
      <div>
        <h3>Browse by</h3>
        <ul className={s.sidebarMenu}>{renderBrowsingMenu}</ul>
      </div>

      {/* bookmarks */}
      <div className={s.bookmarks}>
        <h3>Bookmarks</h3>
        <ul className={s.bookmarksMenu}>{renderBookmarkCategories()}</ul>
      </div>

      {/* <button
        onClick={() => {
          bookmark.add('courses', {
            name: 'Bachelor of Business',
            value: 'bachelorOfBusiness',
          })
        }}>
        Add course
      </button>

      <button
        onClick={() => {
          bookmark.remove('courses', 'bachelorOfBusiness')
        }}>
        Remove course
      </button> */}
    </div>
  )
}

export default Sidebar
