import React from 'react'

// Art

export const IconArt = () => {
  return (
    <svg
      width='27'
      height='24'
      viewBox='0 0 27 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'>
      <rect x='2' y='21' width='25' height='3' rx='1.5' fill='#A1B6CA' />
      <path
        d='M3 21v-4c0-.791.235-1.565.674-2.222.44-.658 1.064-1.17 1.795-1.473.731-.303 1.535-.383 2.311-.228.776.154 1.489.535 2.048 1.095.56.559.94 1.272 1.095 2.048.155.776.075 1.58-.228 2.31-.302.732-.815 1.356-1.473 1.796-.658.44-1.43.674-2.222.674H3z'
        stroke='#333'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M21 3c-2.854.39-5.55 1.544-7.803 3.34-2.253 1.795-3.98 4.165-4.997 6.86M21 3c-.39 2.854-1.544 5.55-3.34 7.803-1.795 2.253-4.165 3.98-6.86 4.997M10.6 9c1.943.897 3.503 2.457 4.4 4.4'
        stroke='#333'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}

// Arts

export const IconArts = () => {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'>
      <rect x='2' y='5' width='8' height='8' rx='4' fill='#A1B6CA' />
      <path
        d='M0 21V19C0 17.9391 0.421427 16.9217 1.17157 16.1716C1.92172 15.4214 2.93913 15 4 15H8C9.06087 15 10.0783 15.4214 10.8284 16.1716C11.5786 16.9217 12 17.9391 12 19V21'
        fill='#A1B6CA'
      />
      <path
        d='M12 11C14.2091 11 16 9.20914 16 7C16 4.79086 14.2091 3 12 3C9.79086 3 8 4.79086 8 7C8 9.20914 9.79086 11 12 11Z'
        stroke='#333333'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M6 21V19C6 17.9391 6.42143 16.9217 7.17157 16.1716C7.92172 15.4214 8.93913 15 10 15H14C15.0609 15 16.0783 15.4214 16.8284 16.1716C17.5786 16.9217 18 17.9391 18 19V21'
        stroke='#333333'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}

// Education

export const IconEducation = () => {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none'>
      <rect
        x='4'
        y='21'
        width='7'
        height='16'
        rx='3.5'
        transform='rotate(-90 4 21)'
        fill='#A1B6CA'
      />
      <path
        d='M21.75 13.2c-.199 0-.39-.08-.53-.22-.141-.14-.22-.331-.22-.53V8.7c0-.199.079-.39.22-.53.14-.141.331-.22.53-.22s.39.079.53.22c.141.14.22.331.22.53v3.75c0 .199-.079.39-.22.53-.14.14-.331.22-.53.22z'
        fill='#333'
      />
      <path
        d='M12.084 12.3c-.487 0-.91-.066-1.333-.21L1.155 8.434C.149 8.024 0 7.39 0 7.05c0-.342.148-.975 1.139-1.379l9.5-3.65c.732-.284 1.987-.286 2.723 0l9.484 3.644C23.852 6.073 24 6.708 24 7.05c0 .342-.148.973-1.14 1.378l-9.5 3.651c-.311.143-.742.22-1.276.22zM1.733 7.05l9.526 3.627c.245.084.498.123.825.123.387 0 .594-.057.697-.104l9.485-3.646-9.444-3.629c-.393-.153-1.254-.151-1.642 0L1.733 7.05z'
        fill='#333'
      />
      <path
        d='M12.084 15.097c-.486 0-.91-.067-1.334-.211l-6.528-2.484.533-1.403 6.502 2.477c.246.082.5.123.825.123.386 0 .593-.057.698-.105l6.448-2.48.539 1.401-6.407 2.462c-.313.144-.744.22-1.276.22z'
        fill='#333'
      />
      <path
        d='M12.081 22.2c-3.862 0-8.116-1.18-8.328-3.774L3.75 11.7c0-.199.079-.39.22-.53.14-.141.331-.22.53-.22s.39.079.53.22c.141.14.22.331.22.53v6.587c.104 1.315 3.232 2.413 6.831 2.413 2.83 0 5.445-.665 6.358-1.616.22-.226.32-.453.31-.688v-6.642c0-.199.079-.39.22-.53.14-.141.33-.22.53-.22.198 0 .39.079.53.22.14.14.22.331.22.53v6.533c.027.694-.226 1.312-.726 1.835-1.472 1.532-4.808 2.078-7.442 2.078zM21 14.7c0 .199.079.39.22.53.14.14.331.22.53.22s.39-.08.53-.22c.141-.14.22-.331.22-.53s-.079-.39-.22-.53c-.14-.141-.331-.22-.53-.22s-.39.079-.53.22c-.141.14-.22.331-.22.53z'
        fill='#333'
      />
    </svg>
  )
}

// Engineering

export const IconEngineering = () => {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none'>
      <rect
        x='4'
        y='20'
        width='16'
        height='3'
        rx='1.5'
        transform='rotate(-90 4 20)'
        fill='#A1B6CA'
      />
      <path
        d='M5 4h14c.265 0 .52.105.707.293.188.187.293.442.293.707v5c0 .265-.105.52-.293.707-.187.188-.442.293-.707.293h-7c-.265 0-.52.105-.707.293-.188.187-.293.442-.293.707v7c0 .265-.105.52-.293.707-.187.188-.442.293-.707.293H5c-.265 0-.52-.105-.707-.293C4.105 19.52 4 19.265 4 19V5c0-.265.105-.52.293-.707C4.48 4.105 4.735 4 5 4M4 8h2m-2 4h3m-3 4h2M8 4v2m4-2v3m4-3v2'
        stroke='#333'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}

// InformationTechnology

export const IconInformationTechnology = () => {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'>
      <rect x='3' y='9' width='6' height='6' rx='3' fill='#A1B6CA' />
      <path
        d='M6 15c1.657 0 3-1.343 3-3S7.657 9 6 9s-3 1.343-3 3 1.343 3 3 3zm12-6c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zm0 12c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zM8.7 10.7l6.6-3.4m-6.6 6l6.6 3.4'
        stroke='#333'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}

// MedicineNurshingAndHealthSciences

export const IconMedicineNurshingAndHealthSciences = () => {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M8.071 9l7.071 7.071-3.535 3.536c-1.953 1.952-5.119 1.952-7.071 0-1.953-1.953-1.953-5.119 0-7.071L8.07 9z'
        fill='#A1B6CA'
      />
      <path
        d='M4.5 12.5l8-8c.928-.928 2.187-1.45 3.5-1.45 1.313 0 2.572.522 3.5 1.45.928.928 1.45 2.187 1.45 3.5 0 1.313-.522 2.572-1.45 3.5l-8 8c-.928.928-2.187 1.45-3.5 1.45-1.313 0-2.572-.522-3.5-1.45-.928-.928-1.45-2.187-1.45-3.5 0-1.313.522-2.572 1.45-3.5m4-4l7 7'
        stroke='#333'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}

// PharmacyAndPharmaceuticalSciences

export const IconPharmacyAndPharmaceuticalSciences = () => {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M8.126 18.73c1.148.762 2.496 1.169 3.874 1.169V3L7 8c-.965.985-1.617 2.232-1.876 3.586-.258 1.354-.111 2.754.423 4.025.534 1.27 1.43 2.355 2.579 3.118z'
        fill='#A1B6CA'
      />
      <path
        d='M12 3l5 5c.965.985 1.617 2.232 1.876 3.586.258 1.354.111 2.754-.423 4.025-.534 1.27-1.43 2.355-2.579 3.118-1.148.763-2.496 1.17-3.874 1.17-1.378 0-2.726-.407-3.874-1.17-1.148-.763-2.045-1.848-2.58-3.118-.533-1.271-.68-2.671-.422-4.025C5.383 10.232 6.035 8.985 7 8l5-5z'
        stroke='#333'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}

// Business

export const IconBusiness = () => {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'>
      <rect x='3' y='6' width='9' height='13' rx='2' fill='#A1B6CA' />
      <path
        d='M19 7H5c-1.105 0-2 .895-2 2v9c0 1.105.895 2 2 2h14c1.105 0 2-.895 2-2V9c0-1.105-.895-2-2-2zM8 7V5c0-.53.21-1.04.586-1.414C8.96 3.21 9.47 3 10 3h4c.53 0 1.04.21 1.414.586C15.79 3.96 16 4.47 16 5v2m-4 5v.01'
        stroke='#333'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M3 13c2.792 1.407 5.874 2.14 9 2.14 3.126 0 6.208-.733 9-2.14'
        stroke='#333'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}

// Science

export const IconScience = () => {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M9 3h6m-5 6h4'
        stroke='#333'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path d='M10 9h4l4 12H6l4-12z' fill='#A1B6CA' />
      <path
        d='M10 3v6L6 20c-.048.097-.073.204-.073.312 0 .109.025.216.074.313.048.097.118.181.205.246s.187.11.294.129h11c.107-.02.207-.064.294-.129.086-.065.157-.15.205-.246.049-.097.074-.204.074-.313 0-.108-.025-.215-.073-.312L14 9V3'
        stroke='#333'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}

// Law

export const IconLaw = () => {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'>
      <path d='M5 14v3.5h7.826l.522 2.864 2.608.636L17 14H5z' fill='#A1B6CA' />
      <path
        d='M15 21H6c-.796 0-1.559-.316-2.121-.879C3.316 19.56 3 18.796 3 18v-1h10v2c0 .53.21 1.04.586 1.414.375.375.884.586 1.414.586zm0 0c.53 0 1.04-.21 1.414-.586C16.79 20.04 17 19.53 17 19V5c0-.396.117-.782.337-1.111.22-.329.532-.585.898-.737.365-.151.767-.19 1.155-.114.388.078.744.268 1.024.548s.47.636.548 1.024c.077.388.037.79-.114 1.155-.152.366-.408.678-.737.898-.329.22-.715.337-1.111.337h-2l-2 14zm4-18H8c-.796 0-1.559.316-2.121.879C5.316 4.44 5 5.204 5 6v11M9 7h4m-4 4h4'
        stroke='#333'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}
