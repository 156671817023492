import React from 'react'
import Courses from './pages/Courses'
import MajorsMinors from './pages/MajorsMinors'
import Units from './pages/Units'
import { Route } from 'components/utils/Router'
import CoursePlans from './pages/CoursePlans'
import Course from './pages/course/Course'
import Major from './pages/major/Major'
import Transition from 'components/utils/Transition'
import s from './content.module.scss'

const Content = ({ plan, setPlan }) => {
  // render

  return (
    <>
      <Transition watch={plan.highlight}>
        <div className={s.highlight}></div>
      </Transition>

      {/* landing / course plans */}
      <Route route='/' transition exact>
        <CoursePlans />
      </Route>

      {/* courses */}
      <Route route='courses' transition exact>
        <Courses />
      </Route>

      <Route route='courses/course' transition>
        <Course {...{ plan, setPlan }} />
      </Route>

      {/* majors minors */}
      <Route route='majors-and-minors' transition exact>
        <MajorsMinors />
      </Route>

      <Route route='majors-and-minors/major' transition>
        <Major {...{ plan, setPlan }} />
      </Route>

      {/* units */}
      <Route route='units' transition exact>
        <Units />
      </Route>
    </>
  )
}

export default Content
