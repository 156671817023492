import React, { useEffect, useState } from 'react'

// regex for removing the first /
const rx = /^\//
const cleanPath = (path) => `/${path.replace(rx, '')}`

// listeners
const listener = {
  add: (fn) => {
    window.addEventListener('routechange', fn)
    window.addEventListener('popstate', fn)
  },
  remove: (fn) => {
    window.removeEventListener('routechange', fn)
    window.removeEventListener('popstate', fn)
  },
}

// <Route />

const Route = ({ children, route, transition, exact }) => {
  const [path, setPath] = useState(cleanPath(window.location.pathname))
  const [routed, setRouted] = useState(
    exact !== null && exact !== undefined
      ? path === cleanPath(route)
      : path.includes(cleanPath(route))
  )
  const [mounted, setMounted] = useState(false)
  const [propMount, setPropMount] = useState(false)

  // transition

  const unmountDelay = transition?.unmountDelay || 300
  const mountDelay = transition?.mountDelay || 300

  useEffect(() => {
    if (!routed) {
      setPropMount(false)
      setTimeout(() => setMounted(false), unmountDelay)
    } else {
      setTimeout(() => {
        setMounted(true)
        setTimeout(() => {
          setPropMount(true)
        }, 10)
      }, mountDelay)
    }
  }, [mountDelay, mounted, routed, unmountDelay])

  useEffect(() => {
    setRouted(
      exact !== null && exact !== undefined
        ? path === cleanPath(route)
        : path.includes(cleanPath(route))
    )
  }, [exact, path, route])

  // listeners

  useEffect(() => {
    const routeChange = (e) => {
      setPath(e.detail || e.state?.page || window.location.pathname)
    }

    listener.add(routeChange)

    return () => {
      listener.remove(routeChange)
    }
  }, [transition])

  // render

  return !transition ? (
    <>{mounted && children}</>
  ) : (
    <>
      {mounted &&
        React.Children.map(children, (child) =>
          React.cloneElement(child, {
            mounted: propMount ? 'true' : null,
          })
        )}
    </>
  )
}

// <Link />

const Link = ({ children, to, className, exact }) => {
  const [active, setActive] = useState(
    exact !== null && exact !== undefined
      ? to === cleanPath(window.location.pathname)
      : cleanPath(window.location.pathname).includes(cleanPath(to))
  )
  const event = new CustomEvent('routechange', { detail: cleanPath(to) })

  const pushState = () =>
    window.history.pushState(
      { page: cleanPath(to), title: 'example title' },
      '',
      cleanPath(to)
    )
  const emitRouteChange = () => window.dispatchEvent(event)

  const handleClick = (e) => {
    e.preventDefault()
    if (cleanPath(window.location.pathname) !== cleanPath(to)) {
      pushState()
      emitRouteChange()
    }
  }

  // check if route is active

  useEffect(() => {
    const routeChange = (e) => {
      const path = cleanPath(window.location.pathname)
      setActive(
        exact !== null && exact !== undefined
          ? to === cleanPath(path)
          : path.includes(cleanPath(to))
      )
    }

    listener.add(routeChange)

    return () => {
      listener.remove(routeChange)
    }
  }, [exact, to])

  // render

  return (
    <a
      href={cleanPath(to)}
      onClick={handleClick}
      className={className}
      active={active ? '' : null}>
      {children}
    </a>
  )
}

export { Route, Link }
