import React from 'react'
import s from './page.module.scss'
import { IconSearch, IconMajorsMinors } from 'components/core/icons/Icons'

const Units = ({ mounted }) => {
  // course list
  const courseList = [
    {
      type: 'Unit',
      name: 'Accounting',
      code: 'ATS1297',
      cp: 6,
    },
    {
      type: 'Unit',
      name: 'Actuarial Science',
      code: 'ATS1297',
      cp: 6,
    },
    {
      type: 'Minor',
      name: 'Banking and Finance',
      code: 'ATS1297',
      cp: 6,
    },
    {
      type: 'Unit',
      name: 'Business (Business Education',
      code: 'ATS1297',
      cp: 6,
    },
    {
      type: 'Unit',
      name: 'Business (Business)',
      code: 'ATS1297',
      cp: 6,
    },
    {
      type: 'Ext Unit',
      name: 'Business (Commerce)',
      code: 'ATS1297',
      cp: 6,
    },
    { type: 'Unit', name: 'Business', code: 'ATS1297', cp: 6 },
    {
      type: 'Unit',
      name: 'Business Administration',
      code: 'ATS1297',
      cp: 6,
    },
    {
      type: 'Unit',
      name: 'Business Administration and Arts',
      code: 'ATS1297',
      cp: 6,
    },
    {
      type: 'Unit',
      name: 'Business Administration and Media',
      code: 'ATS1297',
      cp: 6,
    },
    {
      type: 'Unit',
      name: 'Business and Accounting',
      code: 'ATS1297',
      cp: 6,
    },
    {
      type: 'Unit',
      name: 'Business and Arts',
      code: 'ATS1297',
      cp: 6,
    },
    {
      type: 'Unit',
      name: 'Business (Commerce)',
      code: 'ATS1297',
      cp: 6,
    },
    { type: 'Unit', name: 'Business', code: 'ATS1297', cp: 6 },
    {
      type: 'Unit',
      name: 'Business Administration',
      code: 'ATS1297',
      cp: 6,
    },
  ]

  const renderCourseList = courseList.map((item, i) => {
    return (
      <li key={i}>
        <div className={s.meta}>
          <span className={s.type}>{item.type}</span> • {item.code} • {item.cp}{' '}
          CP
        </div>
        <h3>{item.name}</h3>
      </li>
    )
  })

  // render

  return (
    <div className={s.page} mounted={mounted ? '' : null}>
      {/* header */}
      <h1>
        <IconMajorsMinors />
        <span>Units</span>
      </h1>

      {/* search */}
      <div className={s.search}>
        <h2>I am interested in</h2>

        <div className={s.searchBar}>
          <IconSearch />
          <input
            type='text'
            placeholder='Find any units across Monash University'></input>
        </div>
      </div>

      {/* course list */}
      <div className={s.courseList}>
        <div className={s.tabs}>
          <span className={s.active}>All (1077)</span>
        </div>
        <ul>{renderCourseList}</ul>
      </div>
    </div>
  )
}

export default Units
