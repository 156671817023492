import React, { useState } from 'react'
import './app.scss'
import TopBar from 'components/navigation/topbar/TopBar'
import Main from 'components/main/Main'

function App() {
  const [split, setSplit] = useState(50)
  const [plan, planSet] = useState({
    active: false,
    tab: 'overview',
    majorTab: 'overview',
  })

  const setPlan = (value) => {
    planSet((f) => {
      return { ...f, ...value }
    })
  }

  return (
    <div className='app'>
      <TopBar {...{ split, setSplit, plan }}></TopBar>
      <Main {...{ split, plan, setPlan }}></Main>
    </div>
  )
}

export default App
