import {
  IconBookmark,
  IconChevronDown,
  IconPlus,
} from 'components/core/icons/Icons'
import { Link } from 'components/utils/Router'
import Transition from 'components/utils/Transition'
import React from 'react'
import s from './major.module.scss'

const Major = ({ plan, setPlan, mounted }) => {
  // units

  const units = [
    {
      name: 'Introduction to financial accounting',
      code: 'ACF1100',
      points: 6,
    },
    { name: 'Financial accounting', code: 'ACF2100', points: 6 },
    {
      name: 'Introduction to management accounting',
      code: 'ACF2200',
      points: 6,
    },
    { name: 'Accounting information systems', code: 'ACF2400', points: 6 },
    { name: 'Advanced financial accounting', code: 'ACF3100', points: 6 },
    { name: 'Management accounting', code: 'ACF3200', points: 6 },
  ]

  const renderUnits = units.map((item, i) => {
    return (
      <li key={i}>
        <Link to={`units/unit/${item.name.replace(/ /g, '-')}`}>
          <div className={s.meta}>
            <span className={s.type}>UNIT</span> • {item.code} • {item.points}{' '}
            CP
          </div>
          <h3>{item.name}</h3>
        </Link>
      </li>
    )
  })

  // tabs

  const tabs = [
    { name: 'Overview', value: 'overview' },
    { name: 'Learning outcomes', value: 'learningOutcomes' },
  ]

  const renderTabs = () =>
    tabs.map((item, i) => (
      <div
        key={i}
        className={plan.majorTab === item.value ? s.active : ''}
        onClick={() => setPlan({ tab: item.value })}>
        {item.name}
      </div>
    ))

  // render

  return (
    <div className={s.major} mounted={mounted ? '' : null}>
      {/* header */}

      <header>
        <h1>ACCOUNTG05 - Accounting</h1>

        <div className={s.right}>
          <div className={s.bookmark}>
            <IconBookmark />
          </div>
          <button
            onClick={() =>
              setPlan({
                majorSelected: true,
                highlight: true,
                confirmMajor: true,
              })
            }>
            <IconPlus />
            {plan.majorSelected ? 'Major selected' : 'Add to plan'}
          </button>
        </div>
      </header>

      {/* tab */}

      <div className={s.tabs}>{renderTabs()}</div>
      {/* overview */}

      <Transition watch={plan.majorTab === 'learningOutcomes'}>
        <div className={s.tabContent}>
          <div className={s.card}>
            <div className={s.container}>
              <div className={s.overview}>
                <h2>Course description</h2>

                <p>
                  Knowledge and understanding of accounting is an essential
                  requirement for successful management. The wide range of units
                  offered by the Department of Accounting caters for aspirants
                  seeking success in the accounting profession and also others,
                  majoring in related disciplines, seeking to be competent users
                  of accounting reports.
                </p>
              </div>
            </div>
          </div>
        </div>
      </Transition>

      {/* course structure */}

      <Transition watch={plan.majorTab === 'overview'}>
        <div className={s.tabContent}>
          <div className={s.pagetructure}>
            {/* card */}
            <div className={s.card}>
              <header>
                <div className={s.title}>
                  <h3>Major description</h3>
                  <p>48 Credit Points (CP)</p>
                </div>
              </header>

              <div className={s.container}>
                <p>
                  Knowledge and understanding of accounting is an essential
                  requirement for successful management. The wide range of units
                  offered by the Department of Accounting caters for aspirants
                  seeking success in the accounting profession and also others,
                  majoring in related disciplines, seeking to be competent users
                  of accounting reports.
                </p>
              </div>

              <section>
                <div className={s.sectionHeader}>
                  <h4>Core business units</h4>
                  <div>
                    36 CP <IconChevronDown />
                  </div>
                </div>

                <div className={s.sectionContent}>
                  <ul>{renderUnits}</ul>
                </div>
              </section>

              <section>
                <div className={s.sectionHeader}>
                  <h4>Additional business units</h4>
                  <div>
                    12 CP <IconChevronDown />
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </Transition>
    </div>
  )
}

export default Major
