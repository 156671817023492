export default [
  {
    name: 'Bachelor of Business',
    code: 'B2000',
    overview: `The world of business is challenging, fast-paced and constantly evolving. From finance to food, engineering to entertainment, every industry needs people with business skills. Whether you're interested in working for a large corporation, managing a business or becoming an entrepreneur, you need to know how to thrive in a competitive business landscape. In this course you will develop broad business knowledge and acquire expertise in your chosen area of business practice. With 12 majors to choose from and 10 double-degree options on offer, you will have genuine diversity and depth of choice in your business studies at Monash.`,
    courseStructure: [
      {
        name: 'Business specified study',
        description:
          'This will provide you with a broad foundation for your study of business and expose you to several business disciplines. It will contribute breadth to your knowledge of business and develop your understanding of multi-disciplinary decision making in organisations. It will also give you the opportunity to learn more about each discipline before finalising your choice of major.',
        points: 48,
        structure: [
          {
            name: 'Core units',
            description:
              'You must complete 42 credit points from the following units. Only one accounting unit may be completed. Note: You must complete ACF1100 if you intend to complete a major in Accounting.',
            points: 42,
            units: [
              {
                name: 'Introduction to financial accounting',
                code: 'ACF1100',
                points: 6,
              },
              { name: 'Accounting for managers', code: 'ACF1200', points: 6 },
              { name: 'Foundations of finance', code: 'BFF1001', points: 6 },
              { name: 'Business law', code: 'BTF1010', points: 6 },
              { name: 'Microeconomics', code: 'ECF1100', points: 6 },
              { name: 'Business statistics', code: 'ETF1100', points: 6 },
              {
                name: 'Introduction to management',
                code: 'MGF1010',
                points: 6,
              },
              {
                name: 'Marketing theory and practice',
                code: 'MKF1120',
                points: 6,
              },
            ],
          },
          {
            name:
              'Capstone, project, international or internship experience unit',
            description:
              'You must complete one unit from one of the following lists, unless you are completing a major in Accounting. If you are completing the Accounting major you do not complete this section, as ACF3100 and ACF3200 meet this requirement. Note: If you choose a 12 or 24 credit point unit it will reduce the amount of points available for free electives in Part C which may impact on your ability to complete a major using electives.',
            points: 6,
            options: [
              {
                name: 'Capstone unit',
                description: 'You must complete one of the following units',
                points: 6,
                units: [
                  {
                    name: 'Current issues in business',
                    code: 'BEX3000',
                    points: 6,
                  },
                  {
                    name: 'Sustainability practice and organisations',
                    code: 'BEX3150',
                    points: 6,
                  },
                  { name: 'Business and design', code: 'BEX3300', points: 6 },
                  { name: 'Entrepreneurship', code: 'BEX3411', points: 6 },
                ],
              },
              {
                name: 'Consulting project',
                description: 'You must complete one of the following units',
                points: 6,
                units: [
                  {
                    name: 'Consulting project',
                    code: 'BEX3350',
                    points: 6,
                  },
                  {
                    name: 'Consulting project',
                    code: 'BEX3351',
                    points: 6,
                  },
                  { name: 'Consulting project', code: 'BEX3450', points: 12 },
                  { name: 'Consulting project', code: 'BEX3451', points: 12 },
                  { name: 'Consulting project', code: 'BEX3550', points: 24 },
                ],
              },
              {
                name: 'International experience',
                description: 'You must complete one of the following units',
                points: 6,
                units: [
                  {
                    name: 'International study program in accounting',
                    code: 'ACX3500',
                    points: 6,
                  },
                  {
                    name: 'Innovation and entrepereneurship in Silicon Valley',
                    code: 'BEX3412',
                    points: 6,
                  },
                  {
                    name: 'Innovation and entrepereneurship in Europe',
                    code: 'BEX3413',
                    points: 6,
                  },
                  {
                    name: 'Issues in global business',
                    code: 'BEX3622',
                    points: 6,
                  },
                  {
                    name: 'Engaging with international business',
                    code: 'BEX3722',
                    points: 6,
                  },
                  {
                    name:
                      'Engaging with international business - North America',
                    code: 'BEX3723',
                    points: 6,
                  },
                  {
                    name: 'Engaging with international business - Asia',
                    code: 'BEX3724',
                    points: 6,
                  },
                  {
                    name: 'Engaging with international business - Europe',
                    code: 'BEX3725',
                    points: 6,
                  },
                  {
                    name:
                      'Engaging with international business - Data science and AI at HEC Montreal',
                    code: 'BEX3726',
                    points: 6,
                  },
                  {
                    name:
                      'Engaging with international business - Latin America',
                    code: 'BEX3727',
                    points: 6,
                  },
                  {
                    name: 'Engaging with international business',
                    code: 'BEX3728',
                    points: 12,
                  },
                  {
                    name: 'Engaging with international business - Asia',
                    code: 'BEX3730',
                    points: 12,
                  },
                  {
                    name: 'International study program in banking and finance',
                    code: 'BFX3730',
                    points: 6,
                  },
                  { name: 'European economy', code: 'ECX3750', points: 6 },
                  {
                    name: 'Global study programs in marketing',
                    code: 'MKX3521',
                    points: 6,
                  },
                ],
              },
              {
                name: 'Internships',
                description: 'You must complete one of the following units',
                points: 6,
                units: [
                  {
                    name: 'Industry placement',
                    code: 'BEX3006',
                    points: 6,
                  },
                  {
                    name: 'Industry placement',
                    code: 'BEX3012',
                    points: 12,
                  },
                  {
                    name: 'Industry placement',
                    code: 'BEX3024',
                    points: 24,
                  },
                  {
                    name: 'Industry placement',
                    code: 'BEX3106',
                    points: 6,
                  },
                  {
                    name: 'Industry placement',
                    code: 'BEX3112',
                    points: 12,
                  },
                  {
                    name: 'Marketing internship',
                    code: 'MKX3631',
                    points: 6,
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        name: 'Business listed major',
        description:
          'You must complete at least one major from the list below. Depending on the major chosen, you may have already studied one of the required units as part of your business specified study. If so, you must complete an additional business unit to bring the combined business study to at least 96 credit points from the Faculty of Business and Economics. If you are intending to undertake an honours year, you should ensure you complete the specific units required for honours in your chosen major area of study.',
        points: 48,
        units: [
          { name: 'Accounting', code: 'ACCOUNTG05', points: 48 },
          { name: 'Banking and finance', code: 'BUSBKFIN05', points: 48 },
          { name: 'Business law', code: 'BUSLAW01', points: 48 },
          { name: 'Business management', code: 'BUSMNGMT01', points: 48 },
          { name: 'Marketing', code: 'BUSMRKTG01', points: 48 },
          { name: 'Business statistics', code: 'BUSSTATS01', points: 48 },
          {
            name: 'Economics and business strategy',
            code: 'ECBUSSTR01',
            points: 48,
          },
          { name: 'Human resource management', code: 'HRMNGMT05', points: 48 },
          { name: 'International business', code: 'INTBUS01', points: 48 },
          { name: 'Taxation', code: 'TAXATION05', points: 48 },
        ],
      },
      {
        name: 'Free elective study',
        description:
          'Electives can be chosen from within the Bachelor of Business, including units to complete a second major or minor(s). Enrolment in Faculty of Business and Economics units at campuses other than the campus of enrolment is subject to there being capacity after students from that campus have enrolled. Electives can also be chosen from across the University, including to complete a major or minor from another course if you have met the required prerequisites and there are no restrictions on enrolling in the units. You should also be aware of travel and timetabling limitations. No more than two units can contribute towards two majors, or a major and a minor, in the same course (including a double degree course). The same unit is not credited towards two minors. If you are in a double degree course, some units required for the other degree are credited as electives towards this Business degree.',
        points: 48,
      },
    ],
    learningOutcomes: {
      description: `These course outcomes are aligned with the Australian Qualifications Framework level 7 and Monash Graduate Attributes.
      Upon successful completion of this course it is expected that you will:`,
      outcomes: [
        {
          title: 'be a critical and creative scholar who:',
          list: [
            'produces innovative solutions to problems',
            'applies research skills to business challenges',
            'communicates effectively and perceptively',
          ],
        },
        {
          title: 'be a responsible and effective global citizen who:',
          list: [
            'engages in an internationalised world',
            'exhibits cross cultural competence',
            'demonstrates ethical values',
          ],
        },
        {
          title:
            'demonstrate broad knowledge and technical skills in at least one area of business and be able to provide discipline based solutions relevant to the business, professional and public policy communities that you serve',
        },
        {
          title:
            'demonstrate a well-developed understanding of multi-disciplinary decision making in organisations via application of knowledge from core business discipline areas from outside the chosen major area of study.',
        },
      ],
    },
    progression:
      'Successful completion of this course may provide a pathway to the one year honours program B3701 Bachelor of Commerce (Honours). To be eligible to apply for entry into the Bachelor of Commerce (Honours), you must obtain a distinction grade average (70%) or above in 24 credit points of studies in relevant discipline units at level 3. In addition, some majors require particular level 3 units to be taken for admission to honours. The details are provided with the requirements for each major to which this applies.',
  },
]
