import { IconPlus } from 'components/core/icons/Icons'
import { Link } from 'components/utils/Router'
import React from 'react'
import s from './page.module.scss'

const CoursePlans = ({ mounted, plans }) => {
  // render plans

  // render

  return (
    <div className={s.page} mounted={mounted ? '' : null}>
      <h1>Your course plans</h1>
      <p>You may create as many course plans as you like.</p>

      <div className={s.coursePlans}>
        <ul className={s.plans}>
          <li className={s.newPlan}>
            <Link to='courses'>
              <IconPlus />
              Create new plan
            </Link>
          </li>
        </ul>
      </div>
    </div>
  )
}

export default CoursePlans
