import React, { useRef, useState } from 'react'
import s from './plan.module.scss'
import { IconChevronDown } from 'components/core/icons/Icons'
import { Link } from 'components/utils/Router'
import { IconFirstCoursePlan } from 'components/core/icons/Illustrations'

const Plan = ({ plan, setPlan }) => {
  // plan lists
  const planSem1 = [
    {
      name: 'Business law',
      code: 'BTF1010',
      cp: 6,
      tag: 'Business Specified Major',
    },
    {
      name: 'Intorduction to management',
      code: 'MGF1010',
      cp: 6,
      tag: 'Business Specified Major',
    },
    {
      name: 'Marketing theory and practice',
      code: 'MKF1120',
      cp: 6,
      tag: 'Business Specified Major',
    },
  ]

  const renderPlanSem1 = planSem1.map((item, i) => {
    return (
      <li key={i}>
        <div className={s.meta}>
          <b>{item.code}</b>
          <span>{item.cp}CP</span>
        </div>
        <div className={s.name}>{item.name}</div>
        <div className={s.tag}>
          <span>{item.tag}</span>
        </div>
      </li>
    )
  })

  const planSem2 = [
    {
      name: 'Foundations of finance',
      code: 'BFF1001',
      cp: 6,
      tag: 'Business Specified Major',
    },
    {
      name: 'Microeconomics',
      code: 'ECF1100',
      cp: 6,
      tag: 'Business Specified Major',
    },
    {
      name: 'Business statistics',
      code: 'ETF1100',
      cp: 6,
      tag: 'Business Specified Major',
    },
  ]

  const renderPlanSem2 = planSem2.map((item, i) => {
    return (
      <li key={i}>
        <div className={s.meta}>
          <b>{item.code}</b>
          <span>{item.cp}CP</span>
        </div>
        <div className={s.name}>{item.name}</div>
        <div className={s.tag}>
          <span>{item.tag}</span>
        </div>
      </li>
    )
  })

  // hidden period

  const ref = useRef()
  const [showPeriod, setShowPeriod] = useState(false)

  // render

  return (
    <>
      {/* plan active */}
      {plan.active && (
        <div className={s.plan} highlight={plan.highlight ? '' : null}>
          {/* header */}
          <div className={s.planHighlight} active={plan.highlight ? '' : null}>
            <header>
              {!plan.confirmMajor && (
                <>
                  <div className={s.left}>
                    <span>Your course plan</span>
                    <button>Plan info</button>
                  </div>
                  <div className={s.right}>
                    <button>Export</button>
                  </div>
                </>
              )}

              {plan.confirmMajor && (
                <div className={s.centre}>
                  <h3>Add Accounting as your major</h3>
                  <span>
                    You are adding <b>6</b> required units for this major
                  </span>
                </div>
              )}
            </header>

            {/* periods */}

            {/* 2021 */}
            <div className={s.period}>
              <h2>
                <span>2021</span>
                {plan.confirmMajor && (
                  <span className={s.plusUnits}>+ 2 units</span>
                )}
              </h2>
              <h3>Semester 1 (9 March - 12 June)</h3>

              <ul>
                {!plan.majorSelected ? (
                  <li
                    className={s.required}
                    onClick={() => setPlan({ tab: 'courseStructure' })}>
                    <div className={s.name}>
                      Step 2. Pick ACF1100 or ACF1200 as one of your core units
                    </div>
                    <div className={s.tag}>
                      <span>Business Specified Major</span>
                    </div>
                  </li>
                ) : (
                  <li>
                    <div className={s.meta}>
                      <b>ACF1100</b>
                      <span>6CP</span>
                    </div>
                    <div className={s.name}>
                      Introduction to financial accounting
                    </div>
                    <div className={s.tag}>
                      <span>Business specified major</span>
                    </div>
                    <div className={s.tag}>
                      <span style={{ backgroundColor: '#2C6C7A' }}>
                        Business Listed Major
                      </span>
                    </div>
                  </li>
                )}
                {renderPlanSem1}
              </ul>

              <h3>Semester 2 (3 August - 6 November)</h3>
              <ul>
                {!plan.majorSelected ? (
                  <li
                    className={s.required}
                    onClick={() => setPlan({ tab: 'courseStructure' })}>
                    <div className={s.name}>
                      Step 1. Select one of the Business Listed Majors.
                    </div>
                    <div className={s.tag}>
                      <span style={{ backgroundColor: '#2C6C7A' }}>
                        Business Listed Major
                      </span>
                    </div>
                  </li>
                ) : (
                  <li>
                    <div className={s.meta}>
                      <b>ACF2200</b>
                      <span>6CP</span>
                    </div>
                    <div className={s.name}>
                      Introduction to management accounting
                    </div>
                    <div className={s.tag}>
                      <span style={{ backgroundColor: '#2C6C7A' }}>
                        Business Listed Major
                      </span>
                    </div>
                  </li>
                )}
                {renderPlanSem2}
              </ul>

              {/* ready to enrol */}
              {plan.readyToEnrol && (
                <div className={s.confirmMajor}>
                  <a href='https://portal.m.monash/enrolment-tasks/congratz'>
                    <button>Submit unit selection for 2021</button>
                  </a>
                  <p>Read more about unit selection</p>
                </div>
              )}
            </div>

            {/* 2022 */}

            <div className={s.closedPeriod}>
              <h2 onClick={() => setShowPeriod(!showPeriod)}>
                <span>2022</span>
                <span>
                  {plan.confirmMajor && (
                    <span className={s.plusUnits}>+ 3 units</span>
                  )}
                  <IconChevronDown />
                </span>
              </h2>

              <div
                className={s.hidden}
                ref={ref}
                style={{
                  height: showPeriod ? ref.current.scrollHeight + 'px' : 0,
                }}>
                <h3>Semester 1 (9 March - 12 June)</h3>
                <ul>
                  <li>
                    <div className={s.meta}>
                      <b>ACF2100</b>
                      <span>6CP</span>
                    </div>
                    <div className={s.name}>Financial accounting</div>
                    <div className={s.preReq}>PREQ: ACF1100</div>
                    <div className={s.tag}>
                      <span style={{ backgroundColor: '#2C6C7A' }}>
                        Business Listed Major
                      </span>
                    </div>
                  </li>
                  <li>
                    <div className={s.meta}>
                      <b>ACF2400</b>
                      <span>6CP</span>
                    </div>
                    <div className={s.name}>Accounting infomation systems</div>
                    <div className={s.preReq}>PREQ: ACF1100</div>
                    <div className={s.tag}>
                      <span style={{ backgroundColor: '#2C6C7A' }}>
                        Business Listed Major
                      </span>
                    </div>
                  </li>
                  <li
                    className={s.required}
                    onClick={() => setPlan({ tab: 'courseStructure' })}>
                    <div className={s.name}>
                      You have 8 free electives to pick within or without your
                      faculty
                    </div>
                    <div className={s.tag}>
                      <span style={{ backgroundColor: '#D17A2C' }}>
                        Free elective study
                      </span>
                    </div>
                  </li>
                  <li
                    className={s.required}
                    onClick={() => setPlan({ tab: 'courseStructure' })}>
                    <div className={s.name}></div>
                    <div className={s.tag}>
                      <span style={{ backgroundColor: '#D17A2C' }}>
                        Free elective study
                      </span>
                    </div>
                  </li>
                </ul>

                <h3>Semester 2 (3 Aug - 6 Nov)</h3>
                <ul>
                  <li>
                    <div className={s.meta}>
                      <b>ACF3200</b>
                      <span>6CP</span>
                    </div>
                    <div className={s.name}>Management accounting</div>
                    <div className={s.preReq}>PREQ: ACF2200</div>
                    <div className={s.tag}>
                      <span style={{ backgroundColor: '#2C6C7A' }}>
                        Business Listed Major
                      </span>
                    </div>
                  </li>
                  <li
                    className={s.required}
                    onClick={() => setPlan({ tab: 'courseStructure' })}>
                    <div className={s.meta}>
                      <b>Additional business units required.</b>
                    </div>
                    <div className={s.name}>
                      Select <u>additional business units</u> in Accounting
                      major
                    </div>
                    <div className={s.tag}>
                      <span style={{ backgroundColor: '#2C6C7A' }}>
                        Business Listed Major
                      </span>
                    </div>
                  </li>
                  <li
                    className={s.required}
                    onClick={() => setPlan({ tab: 'courseStructure' })}>
                    <div className={s.name}></div>
                    <div className={s.tag}>
                      <span style={{ backgroundColor: '#D17A2C' }}>
                        Free elective study
                      </span>
                    </div>
                  </li>
                  <li
                    className={s.required}
                    onClick={() => setPlan({ tab: 'courseStructure' })}>
                    <div className={s.name}></div>
                    <div className={s.tag}>
                      <span style={{ backgroundColor: '#D17A2C' }}>
                        Free elective study
                      </span>
                    </div>
                  </li>
                </ul>
              </div>
            </div>

            {/* 2023 */}

            <div className={s.closedPeriod}>
              <h2>
                <span>2023</span>
                <span>
                  {plan.confirmMajor && (
                    <span className={s.plusUnits}>+ 1 unit</span>
                  )}
                  <IconChevronDown />
                </span>
              </h2>
            </div>

            {/* confirm major selection */}
            {plan.confirmMajor && (
              <div className={s.confirmMajor}>
                <button
                  onClick={() =>
                    setPlan({
                      confirmMajor: false,
                      highlight: false,
                      readyToEnrol: true,
                    })
                  }>
                  Add Accounting Major to your plan
                </button>
                <p>Cancel</p>
              </div>
            )}
          </div>
        </div>
      )}

      {/* no active plan */}
      {!plan.active && (
        <div className={s.plan}>
          <div className={s.inactive}>
            <IconFirstCoursePlan />

            <div>Creat your</div>
            <h1>First course plan</h1>

            <ul>
              <li>
                <span>1</span>
                <Link to='courses'>Browse for courses</Link>
              </li>
              <li>
                <span>2</span>Select a course and create a plan
              </li>
              <li>
                <span>3</span>Enjoy planning your future study!
              </li>
            </ul>
          </div>
        </div>
      )}
    </>
  )
}

export default Plan
