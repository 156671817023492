import {
  IconBookmark,
  IconCheck,
  IconChevronDown,
} from 'components/core/icons/Icons'
import courses from 'data/courses'
import React, { useState } from 'react'
import s from './course.module.scss'
import srcBusiness from 'assets/business.jpg'
import Transition from 'components/utils/Transition'
import { Link } from 'components/utils/Router'

const Course = ({ plan, setPlan, mounted }) => {
  const courseName = window.location.pathname
    .split('/')
    .pop()
    .replace(/-/g, ' ')

  const [course] = useState(
    courses.filter((item) => item.name.toLowerCase() === courseName)[0]
  )

  // tabs

  const tabs = [
    { name: 'Overview', value: 'overview' },
    { name: 'Course structure', value: 'courseStructure' },
    { name: 'Learning outcomes', value: 'learningOutcomes' },
  ]

  const renderTabs = () =>
    tabs.map((item, i) => (
      <div
        key={i}
        className={plan.tab === item.value ? s.active : ''}
        onClick={() => setPlan({ tab: item.value })}>
        {item.name}
      </div>
    ))

  // majors

  const majors = [
    { name: 'Accounting', code: 'ACCOUNTG05', points: 48 },
    { name: 'Banking and finance', code: 'BUSBKFIN05', points: 48 },
    { name: 'Business law', code: 'BUSLAW01', points: 48 },
    { name: 'Business management', code: 'BUSMNGMT01', points: 48 },
    { name: 'Marketing', code: 'BUSMRKTG01', points: 48 },
    { name: 'Business statistics', code: 'BUSSTATS01', points: 48 },
    {
      name: 'Economics and business strategy',
      code: 'ECBUSSTR01',
      points: 48,
    },
    { name: 'Human resource management', code: 'HRMNGMT05', points: 48 },
    { name: 'International business', code: 'INTBUS01', points: 48 },
    { name: 'Taxation', code: 'TAXATION05', points: 48 },
  ]

  const renderMajors = majors.map((item, i) => {
    return (
      <li key={i}>
        <Link to={`majors-and-minors/major/${item.name.replace(/ /g, '-')}`}>
          <div className={s.meta}>
            <span className={s.type}>Major</span> • {item.code} • {item.points}{' '}
            CP
          </div>
          <h3>{item.name}</h3>
        </Link>
      </li>
    )
  })

  // bookmark

  const [marked, setMarked] = useState(false)

  // render

  return (
    <div className={s.course} mounted={mounted ? '' : null}>
      <div className={s.hero}>
        <img src={srcBusiness} alt='business'></img>
        <div className={s.tag}>Course</div>
      </div>

      {/* header */}

      <header>
        <h1>
          {course.code} - {course.name}
        </h1>

        <div className={s.right}>
          <div
            className={s.bookmark}
            onClick={() => setMarked(!marked)}
            marked={marked ? '' : null}>
            <IconBookmark />
          </div>
          <button onClick={() => setPlan({ active: true })}>
            <IconCheck />
            {plan.active ? 'Plan created' : 'Create plan'}
          </button>
        </div>
      </header>

      {/* tab */}

      <div className={s.tabs}>{renderTabs()}</div>
      {/* overview */}

      <Transition watch={plan.tab === 'overview'}>
        <div className={s.tabContent}>
          <div className={s.card}>
            <div className={s.container}>
              <div className={s.overview}>
                <h2>Course description</h2>

                <p>{course.overview}</p>
              </div>
            </div>
          </div>
        </div>
      </Transition>

      {/* course structure */}

      <Transition watch={plan.tab === 'courseStructure'}>
        <div className={s.tabContent}>
          <div className={s.pagetructure}>
            {/* card */}
            <div className={s.card}>
              <header>
                <div className={s.title}>
                  <h3>
                    <b style={{ backgroundColor: '#214296' }}>
                      Business Specific Study
                    </b>
                    <span>48 Credit Points (CP)</span>
                  </h3>
                </div>
              </header>

              <div className={s.container}>
                <p>
                  This will provide you with a broad foundation for your study
                  of business and expose you to several business disciplines.
                </p>
              </div>

              <section>
                <div className={s.sectionHeader}>
                  <h4>Core units</h4>
                  <div>
                    42 CP <IconChevronDown />
                  </div>
                </div>
              </section>

              <section>
                <div className={s.sectionHeader}>
                  <h4>
                    Capstone, project, international or internship experience
                    unit
                  </h4>
                  <div>
                    6 CP <IconChevronDown />
                  </div>
                </div>
              </section>
            </div>

            {/* card */}
            <div className={s.card}>
              <header>
                <div className={s.title}>
                  <h3>
                    <b style={{ backgroundColor: '#008A25' }}>
                      Business Listed Major
                    </b>
                    <span>48 Credit Points (CP)</span>
                  </h3>
                </div>
              </header>

              <div className={s.container}>
                <p>
                  This will provide you with a focused program of study that
                  will develop your expertise in one discipline area.
                </p>
              </div>

              <section>
                <div className={s.sectionHeader}>
                  <h4>Majors</h4>
                  <div>
                    48 CP <IconChevronDown />
                  </div>
                </div>

                <div className={s.sectionContent}>
                  <ul>{renderMajors}</ul>
                </div>
              </section>
            </div>

            {/* card */}
            <div className={s.card}>
              <header>
                <div className={s.title}>
                  <h3>
                    <b style={{ backgroundColor: '#D17A2C' }}>
                      Free Elective Study
                    </b>
                    <span>48 Credit Points (CP)</span>
                  </h3>
                </div>
              </header>

              <div className={s.container}>
                <p>
                  This will enable you to further develop your knowledge of your
                  chosen major, or business more broadly, or study a second
                  business major. Alternatively you can select units from across
                  the University in which you are eligible to enrol.
                </p>
              </div>
            </div>
          </div>
        </div>
      </Transition>
    </div>
  )
}

export default Course
