import React from 'react'

// FirstCoursePlan

export const IconFirstCoursePlan = () => {
  return (
    <svg
      width='180'
      height='140'
      viewBox='0 0 180 140'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M0 8V132C0 136.418 3.58172 140 8 140H172C176.418 140 180 136.418 180 132V8C180 3.58172 176.418 0 172 0H8C3.58172 0 0 3.58172 0 8Z'
        fill='white'
        fillOpacity='0.1'
      />
      <path
        d='M112 25C112 22.7909 113.791 21 116 21H163C165.209 21 167 22.7909 167 25V125.698C167 127.907 165.209 129.698 163 129.698H116C113.791 129.698 112 127.907 112 125.698V25Z'
        fill='#204F88'
      />
      <rect x='13' y='21' width='92' height='108.698' rx='4' fill='#E8EEF4' />
      <circle cx='15' cy='11' r='2' fill='white' />
      <circle cx='23' cy='11' r='2' fill='white' />
      <circle cx='31' cy='11' r='2' fill='white' />
      <rect x='131' y='94' width='26' height='26' rx='4' fill='white' />
      <path
        d='M140 114L148 114'
        stroke='#204F88'
        strokeWidth='5'
        strokeLinecap='round'
      />
      <rect
        x='95.6665'
        y='52.8828'
        width='4'
        height='3.98892'
        rx='1.99446'
        fill='#333333'
      />
      <rect
        x='87'
        y='52.8828'
        width='4'
        height='3.98892'
        rx='1.99446'
        fill='#333333'
      />
      <g filter='url(#filter0_d)'>
        <rect
          x='142'
          y='70.833'
          width='32'
          height='31.9113'
          rx='15.9557'
          transform='rotate(-180 142 70.833)'
          fill='white'
        />
      </g>
      <path
        d='M126 61.8579L126 48.8939'
        stroke='#333333'
        strokeWidth='3'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M132 54.8772L120 54.8772'
        stroke='#333333'
        strokeWidth='3'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <defs>
        <filter
          id='filter0_d'
          x='94'
          y='26.9216'
          width='64'
          height='63.9113'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'>
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
          />
          <feOffset dy='4' />
          <feGaussianBlur stdDeviation='8' />
          <feColorMatrix
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0'
          />
          <feBlend
            mode='normal'
            in2='BackgroundImageFix'
            result='effect1_dropShadow'
          />
          <feBlend
            mode='normal'
            in='SourceGraphic'
            in2='effect1_dropShadow'
            result='shape'
          />
        </filter>
      </defs>
    </svg>
  )
}
