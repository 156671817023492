import React from 'react'
import s from './page.module.scss'
import { IconSearch, IconCourses } from 'components/core/icons/Icons'
import {
  IconArt,
  IconArts,
  IconBusiness,
  IconEducation,
  IconEngineering,
  IconInformationTechnology,
  IconLaw,
  IconMedicineNurshingAndHealthSciences,
  IconPharmacyAndPharmaceuticalSciences,
  IconScience,
} from 'components/core/icons/FacultyIcons'
import { Link } from 'components/utils/Router'

const Courses = ({ mounted }) => {
  // interest areas list
  const interestAreasList = [
    { name: 'Art, Design, and Architecture', icon: <IconArt /> },
    { name: 'Arts, Humanities, and Social Sciences', icon: <IconArts /> },
    { name: 'Education', icon: <IconEducation /> },
    { name: 'Engineering', icon: <IconEngineering /> },
    { name: 'Information Technology', icon: <IconInformationTechnology /> },
    {
      name: 'Medicine, Nursing & Health Sciences',
      icon: <IconMedicineNurshingAndHealthSciences />,
    },
    {
      name: 'Pharmacy & Pharmaceutical Sciences',
      icon: <IconPharmacyAndPharmaceuticalSciences />,
    },
    { name: 'Business', icon: <IconBusiness /> },
    { name: 'Science', icon: <IconScience /> },
    { name: 'Law', icon: <IconLaw /> },
  ]

  const renderInterestAreas = interestAreasList.map((item, i) => {
    return (
      <li key={i}>
        {item.icon}
        <span>{item.name}</span>
      </li>
    )
  })

  // course list
  const courseList = [
    { name: 'Bachelor of Accounting', code: 'B2029', cp: 144 },
    { name: 'Bachelor of Actuarial Science', code: 'B2033', cp: 144 },
    { name: 'Bachelor of Banking and Finance', code: 'B2042', cp: 144 },
    { name: 'Bachelor of Business', code: 'B2000', cp: 144 },
    { name: 'Bachelor of Business Administration', code: 'B2007', cp: 144 },
    {
      name: 'Bachelor of Business Administration and Arts',
      code: 'B2046',
      cp: 144,
    },
    {
      name: 'Bachelor of Business Administration and Media',
      code: 'B2045',
      cp: 144,
    },
    { name: 'Bachelor of Business and Accounting', code: 'B2040', cp: 144 },
    { name: 'Bachelor of Business and Arts', code: 'B2019', cp: 144 },
    {
      name: 'Bachelor of Business and Banking and Finance',
      code: 'B2035',
      cp: 144,
    },
    {
      name: 'Bachelor of Business and Information Technology',
      code: 'B2017',
      cp: 144,
    },
    {
      name: 'Bachelor of Business and International Business',
      code: 'B2041',
      cp: 144,
    },
  ]

  const renderCourseList = courseList.map((item, i) => {
    return (
      <li key={i}>
        <Link
          to={`courses/course/${item.name.toLowerCase().replace(/ /g, '-')}`}>
          <div className={s.meta}>
            <span className={s.type}>COURSE</span> • {item.code} • {item.cp} CP
          </div>
          <h3>{item.name}</h3>
        </Link>
      </li>
    )
  })

  // render

  return (
    <div className={s.page} mounted={mounted ? '' : null}>
      {/* header */}
      <h1>
        <IconCourses />
        <span>Courses</span>
      </h1>
      {/* search */}
      <div className={s.search}>
        <h2>I am interested in</h2>

        <div className={s.searchBar}>
          <IconSearch />
          <input
            type='text'
            placeholder='Find any course across Monash University'></input>
        </div>
      </div>
      {/* interest areas */}
      <div className={s.interestAreas}>
        <h2>Explore by interest areas</h2>
        <ul>{renderInterestAreas}</ul>
      </div>
      {/* course list */}
      <div className={s.courseList}>
        <div className={s.tabs}>
          <span className={s.active}>All (42)</span>
          <span>Undergraduate</span>
          <span>Graduate</span>
          <span>Double Degrees</span>
          <span>Professional Development</span>
        </div>
        <ul>{renderCourseList}</ul>
      </div>
    </div>
  )
}

export default Courses
