import React from 'react'

// SplitLeft

export const IconSplitLeft = () => {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none'>
      <path
        d='M18 4H6c-1.105 0-2 .895-2 2v12c0 1.105.895 2 2 2h12c1.105 0 2-.895 2-2V6c0-1.105-.895-2-2-2zM9 4v16'
        stroke='#fff'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}

// SplitRight

export const IconSplitRight = () => {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none'>
      <path
        d='M18 4H6C4.89543 4 4 4.89543 4 6V18C4 19.1046 4.89543 20 6 20H18C19.1046 20 20 19.1046 20 18V6C20 4.89543 19.1046 4 18 4Z'
        stroke='white'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M15 4V20'
        stroke='white'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}

// SplitCentre

export const IconSplitCentre = () => {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none'>
      <path
        d='M18 4H6C4.89543 4 4 4.89543 4 6V18C4 19.1046 4.89543 20 6 20H18C19.1046 20 20 19.1046 20 18V6C20 4.89543 19.1046 4 18 4Z'
        stroke='white'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M12 4V20'
        stroke='white'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}

// Clipboard

export const IconClipboard = () => {
  return (
    <svg width='32' height='32' viewBox='0 0 32 32' fill='none'>
      <path
        d='M12 6.666H9.332c-.708 0-1.386.281-1.886.781s-.781 1.178-.781 1.886v16c0 .707.281 1.385.781 1.885S8.625 28 9.333 28h13.333c.707 0 1.386-.28 1.886-.78s.78-1.18.78-1.886v-16c0-.708-.28-1.386-.78-1.886s-1.179-.781-1.886-.781h-2.667'
        stroke='#204F88'
        strokeWidth='3'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M17.333 4h-2.666C13.194 4 12 5.194 12 6.667c0 1.472 1.194 2.666 2.667 2.666h2.666C18.806 9.333 20 8.14 20 6.667 20 5.194 18.806 4 17.333 4zM12 16h.013m5.321 0h2.667M12 21.334h.013m5.321 0h2.667'
        stroke='#204F88'
        strokeWidth='3'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}

// Check

export const IconCheck = () => {
  return (
    <svg width='32' height='32' viewBox='0 0 32 32' fill='none'>
      <path
        d='M12 14.667l4 4L26.667 8'
        stroke='#204F88'
        strokeWidth='3'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M26.667 16v8c0 .708-.28 1.386-.78 1.886s-1.18.781-1.886.781H8c-.708 0-1.386-.28-1.886-.78s-.781-1.18-.781-1.886V8c0-.708.28-1.386.781-1.886.5-.5 1.178-.781 1.886-.781h12'
        stroke='#204F88'
        strokeWidth='3'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}

// Swatch

export const IconSwatch = () => {
  return (
    <svg width='32' height='32' viewBox='0 0 32 32' fill='none'>
      <path
        d='M25.334 4h-5.333c-.708 0-1.386.28-1.886.781-.5.5-.781 1.178-.781 1.886v16c0 1.414.562 2.77 1.562 3.77 1 1.001 2.357 1.563 3.771 1.563 1.415 0 2.771-.562 3.772-1.562 1-1 1.562-2.357 1.562-3.771v-16c0-.708-.281-1.386-.781-1.886S26.04 4 25.334 4z'
        stroke='#204F88'
        strokeWidth='3'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M17.332 9.8l-2.666-2.666c-.5-.5-1.178-.78-1.886-.78-.707 0-1.385.28-1.885.78l-3.77 3.77c-.5.501-.781 1.18-.781 1.886 0 .707.28 1.386.78 1.886l12 12'
        stroke='#204F88'
        strokeWidth='3'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M9.733 17.334H6.667c-.708 0-1.386.28-1.886.781-.5.5-.781 1.178-.781 1.886v5.333c0 .707.28 1.386.781 1.886.5.5 1.178.78 1.886.78h16m-.001-5.334v.013'
        stroke='#204F88'
        strokeWidth='3'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}

// ChevronDown

export const IconChevronDown = () => {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      strokeWidth='1.5'
      stroke='#000'
      fill='none'
      strokeLinecap='round'
      strokeLinejoin='round'>
      <path d='M6 9l6 6 6-6' />
    </svg>
  )
}

// Shield

export const IconShield = () => {
  return (
    <svg width='32' height='32' viewBox='0 0 32 32' fill='none'>
      <path
        d='M12 16l2.667 2.667L20 13.334'
        stroke='#636363'
        strokeWidth='3'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M16 4c3.115 2.755 7.18 4.19 11.334 4 .605 2.057.79 4.215.544 6.346-.246 2.13-.917 4.189-1.974 6.055-1.058 1.865-2.48 3.5-4.18 4.805C20.023 26.511 18.076 27.462 16 28c-2.075-.538-4.022-1.489-5.723-2.794-1.701-1.305-3.123-2.94-4.18-4.805-1.057-1.866-1.728-3.925-1.974-6.055s-.06-4.289.544-6.346C8.821 8.19 12.886 6.755 16 4'
        stroke='#636363'
        strokeWidth='3'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}

// Home

export const IconHome = () => {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      strokeWidth='1.5'
      stroke='#000'
      fill='none'
      strokeLinecap='round'
      strokeLinejoin='round'>
      <path d='M5 12v7a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2v-7' />
      <path d='M9 21v-6a2 2 0 0 1 2-2h2a2 2 0 0 1 2 2v6M5 12H3l9-9 9 9h-2' />
    </svg>
  )
}

// Apps

export const IconApps = () => {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      strokeWidth='1.5'
      stroke='#000'
      fill='none'
      strokeLinecap='round'
      strokeLinejoin='round'>
      <path d='M14 7h6m-3-3v6' />
      <rect x='4' y='4' width='6' height='6' rx='1' />
      <rect x='4' y='14' width='6' height='6' rx='1' />
      <rect x='14' y='14' width='6' height='6' rx='1' />
    </svg>
  )
}

// Search

export const IconSearch = () => {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      strokeWidth='1.5'
      stroke='#000'
      fill='none'
      strokeLinecap='round'
      strokeLinejoin='round'>
      <circle cx='10' cy='10' r='7' />
      <path d='M21 21l-6-6' />
    </svg>
  )
}

// Plans

export const IconPlans = () => {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      strokeWidth='1.5'
      stroke='#000'
      fill='none'
      strokeLinecap='round'
      strokeLinejoin='round'>
      <rect x='4' y='4' width='6' height='5' rx='2' />
      <rect x='4' y='13' width='6' height='7' rx='2' />
      <rect x='14' y='4' width='6' height='7' rx='2' />
      <rect x='14' y='15' width='6' height='5' rx='2' />
    </svg>
  )
}

// Graph

export const IconGraph = () => {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      strokeWidth='1.5'
      stroke='#000'
      fill='none'
      strokeLinecap='round'
      strokeLinejoin='round'>
      <path d='M4 19h16M4 15l4-6 4 2 4-5 4 4' />
    </svg>
  )
}

// TrendingUp

export const IconTrendingUp = () => {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      strokeWidth='1.5'
      stroke='#000000'
      fill='none'
      strokeLinecap='round'
      strokeLinejoin='round'>
      <polyline points='3 17 9 11 13 15 21 7' />
      <polyline points='14 7 21 7 21 14' />
    </svg>
  )
}

// StackedPills

export const IconStackedPills = () => {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      strokeWidth='1.5'
      stroke='#000'
      fill='none'
      strokeLinecap='round'
      strokeLinejoin='round'>
      <rect x='4' y='4' width='16' height='6' rx='2' />
      <rect x='4' y='14' width='16' height='6' rx='2' />
    </svg>
  )
}

// BigPillLittlePill

export const IconBigPillLittlePill = () => {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      strokeWidth='1.5'
      stroke='#000000'
      fill='none'
      strokeLinecap='round'
      strokeLinejoin='round'>
      <rect x='4' y='4' width='6' height='16' rx='2' />
      <rect x='14' y='4' width='6' height='10' rx='2' />
    </svg>
  )
}

// Columns

export const IconColumns = () => {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      strokeWidth='1.5'
      stroke='#000'
      fill='none'
      strokeLinecap='round'
      strokeLinejoin='round'>
      <path d='M4 6h5.5M4 10h5.5M4 14h5.5M4 18h5.5m5-12H20m-5.5 4H20m-5.5 4H20m-5.5 4H20' />
    </svg>
  )
}

// Lock

export const IconLock = () => {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      strokeWidth='1.5'
      stroke='#000'
      fill='none'
      strokeLinecap='round'
      strokeLinejoin='round'>
      <path d='M8 11V7a4 4 0 0 1 8 0v4' />
      <rect x='5' y='11' width='14' height='10' rx='2' />
      <circle cx='12' cy='16' r='1' />
    </svg>
  )
}

// TriangleRight

export const IconTriangleRight = () => {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      strokeWidth='1.5'
      stroke='#000'
      fill='none'
      strokeLinecap='round'
      strokeLinejoin='round'>
      <path d='M9 18l6-6-6-6v12' />
    </svg>
  )
}

// Chat

export const IconChat = () => {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      strokeWidth='1.5'
      stroke='#000'
      fill='none'
      strokeLinecap='round'
      strokeLinejoin='round'>
      <path d='M21 14l-3-3h-7a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h9a1 1 0 0 1 1 1v10m-7 1v2a1 1 0 0 1-1 1H6l-3 3V11a1 1 0 0 1 1-1h2' />
    </svg>
  )
}

// Bookmark

export const IconBookmark = () => {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      strokeWidth='1.5'
      stroke='#000000'
      fill='none'
      strokeLinecap='round'
      strokeLinejoin='round'>
      <path d='M9 4h6a2 2 0 0 1 2 2v14l-5-3l-5 3v-14a2 2 0 0 1 2 -2' />
    </svg>
  )
}

// Mail

export const IconMail = () => {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24'>
      <path d='M20 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 14H4V8l8 5 8-5v10zm-8-7L4 6h16l-8 5z' />
    </svg>
  )
}

// Globe

export const IconGlobe = () => {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24'>
      <path d='M11.99 2C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zm6.93 6h-2.95c-.32-1.25-.78-2.45-1.38-3.56 1.84.63 3.37 1.91 4.33 3.56zM12 4.04c.83 1.2 1.48 2.53 1.91 3.96h-3.82c.43-1.43 1.08-2.76 1.91-3.96zM4.26 14C4.1 13.36 4 12.69 4 12s.1-1.36.26-2h3.38c-.08.66-.14 1.32-.14 2 0 .68.06 1.34.14 2H4.26zm.82 2h2.95c.32 1.25.78 2.45 1.38 3.56-1.84-.63-3.37-1.9-4.33-3.56zm2.95-8H5.08c.96-1.66 2.49-2.93 4.33-3.56C8.81 5.55 8.35 6.75 8.03 8zM12 19.96c-.83-1.2-1.48-2.53-1.91-3.96h3.82c-.43 1.43-1.08 2.76-1.91 3.96zM14.34 14H9.66c-.09-.66-.16-1.32-.16-2 0-.68.07-1.35.16-2h4.68c.09.65.16 1.32.16 2 0 .68-.07 1.34-.16 2zm.25 5.56c.6-1.11 1.06-2.31 1.38-3.56h2.95c-.96 1.65-2.49 2.93-4.33 3.56zM16.36 14c.08-.66.14-1.32.14-2 0-.68-.06-1.34-.14-2h3.38c.16.64.26 1.31.26 2s-.1 1.36-.26 2h-3.38z' />
    </svg>
  )
}

// Plus

export const IconPlus = () => {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24'>
      <path d='M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z' />
    </svg>
  )
}

// Calendar

export const IconCalendar = () => {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24'>
      <path d='M9 11H7v2h2v-2zm4 0h-2v2h2v-2zm4 0h-2v2h2v-2zm2-7h-1V2h-2v2H8V2H6v2H5c-1.11 0-1.99.9-1.99 2L3 20c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 16H5V9h14v11z' />
    </svg>
  )
}

// Bell

export const IconBell = () => {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24'>
      <path d='M12 22c1.1 0 2-.9 2-2h-4c0 1.1.9 2 2 2zm6-6v-5c0-3.07-1.63-5.64-4.5-6.32V4c0-.83-.67-1.5-1.5-1.5s-1.5.67-1.5 1.5v.68C7.64 5.36 6 7.92 6 11v5l-2 2v1h16v-1l-2-2zm-2 1H8v-6c0-2.48 1.51-4.5 4-4.5s4 2.02 4 4.5v6z' />
    </svg>
  )
}

// Courses

export const IconCourses = () => {
  return (
    <svg
      width='24'
      height='23'
      viewBox='0 0 24 23'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'>
      <rect
        width='24'
        height='10'
        rx='2'
        transform='matrix(-1 0 0 1 24 0)'
        fill='#204F88'
      />
      <rect
        width='24'
        height='10'
        rx='2'
        transform='matrix(-1 0 0 1 24 13)'
        fill='#204F88'
      />
    </svg>
  )
}

// MajorsMinors

export const IconMajorsMinors = () => {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'>
      <rect
        width='10'
        height='10'
        rx='2'
        transform='matrix(-1 0 0 1 10 0)'
        fill='#A1B6CA'
      />
      <rect
        width='10'
        height='10'
        rx='2'
        transform='matrix(-1 0 0 1 10 14)'
        fill='#204F88'
      />
      <rect
        width='10'
        height='10'
        rx='2'
        transform='matrix(-1 0 0 1 24 0)'
        fill='#A1B6CA'
      />
      <rect
        width='10'
        height='10'
        rx='2'
        transform='matrix(-1 0 0 1 24 14)'
        fill='#A1B6CA'
      />
    </svg>
  )
}

// Units

export const IconUnits = () => {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'>
      <rect
        width='24'
        height='24'
        rx='2'
        transform='matrix(-1 0 0 1 24 0)'
        fill='#E8EEF4'
      />
      <rect
        width='8'
        height='4'
        rx='2'
        transform='matrix(-1 0 0 1 20 16)'
        fill='#204F88'
      />
    </svg>
  )
}
