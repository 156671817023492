import React from 'react'
import s from './topbar.module.scss'
import {
  IconHome,
  IconSearch,
  IconSplitLeft,
  IconSplitCentre,
  IconSplitRight,
} from 'components/core/icons/Icons'
import { IconMonashLogo } from 'components/core/icons/Logos'
import Transition from 'components/utils/Transition'

const TopBar = ({ split, setSplit, plan }) => {
  // render

  return (
    <nav className={s.topBar}>
      <Transition watch={plan.highlight}>
        <div className={s.highlight}></div>
      </Transition>
      <div className={s.logo}>
        <IconMonashLogo></IconMonashLogo>
      </div>
      <div className={s.flexiContainer}>
        <div className={s.left}>
          <ul className={s.container}>
            <li className={s.selected}>
              <IconHome></IconHome> Course Planner
            </li>

            {/* <li>
            <IconApps></IconApps>
            <span className={`${split <= 50 ? s.show : ''}`}>All Apps</span>
          </li> */}
          </ul>

          <div className={s.search}>
            <IconSearch></IconSearch>
            <span className={`${split <= 50 ? s.show : ''}`}>Search</span>
          </div>
        </div>

        {plan.active && (
          <div className={s.right} style={{ width: `${split}%` }}>
            <ul className={s.splitControls}>
              <li
                onClick={(e) => setSplit(70)}
                className={`${split === 70 ? s.active : ''}`}>
                <IconSplitLeft></IconSplitLeft>
              </li>
              <li
                onClick={(e) => setSplit(50)}
                className={`${split === 50 ? s.active : ''}`}>
                <IconSplitCentre></IconSplitCentre>
              </li>
              <li
                onClick={(e) => setSplit(30)}
                className={`${split === 30 ? s.active : ''}`}>
                <IconSplitRight></IconSplitRight>
              </li>
            </ul>
            <div className={s.other}>Exit</div>
          </div>
        )}
      </div>
    </nav>
  )
}

export default TopBar
