import React, { useState, useEffect } from 'react'

function Transition({ children, watch, unmountDelay = 300, mountDelay = 300 }) {
  const [mounted, setMounted] = useState(watch)
  const [propMount, setClassMount] = useState(false)
  useEffect(() => {
    if (!watch) {
      setClassMount(false)
      setTimeout(() => setMounted(false), unmountDelay)
    } else {
      setTimeout(() => {
        setMounted(true)
        setTimeout(() => {
          setClassMount(true)
        }, 10)
      }, mountDelay)
    }
  }, [mountDelay, watch, unmountDelay])

  // render

  return (
    <>
      {mounted && (
        <>
          {React.Children.map(children, (child) =>
            React.cloneElement(child, {
              mounted: propMount ? 'true' : null,
            })
          )}
        </>
      )}
    </>
  )
}

export default Transition
