import React, { useState } from 'react'
import s from './main.module.scss'
import Sidebar from 'components/navigation/sidebar/Sidebar'
import Content from 'components/content/Content'
import Plan from 'components/plan/Plan'

const Main = ({ split, plan, setPlan }) => {
  const [bookmarks, setBookmarks] = useState({
    courses: [],
    majorsMinors: [],
    units: [],
    careerPathways: [],
  })

  // render

  return (
    <main className={s.main}>
      <Sidebar {...{ bookmarks, setBookmarks, plan }}></Sidebar>
      <div
        className={s.browse}
        style={{
          width: plan.active ? `${100 - split}%` : 'calc(100% - 330px)',
        }}>
        <Content {...{ bookmarks, setBookmarks, plan, setPlan }}></Content>
      </div>
      <div
        className={s.plan}
        style={{ width: plan.active ? `${split}%` : '330px' }}>
        <Plan {...{ plan, setPlan }} />
      </div>
    </main>
  )
}

export default Main
