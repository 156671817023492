import React from 'react'
import s from './page.module.scss'
import { IconSearch, IconMajorsMinors } from 'components/core/icons/Icons'
import { Link } from 'components/utils/Router'

const MajorsMinors = ({ mounted }) => {
  // course list
  const courseList = [
    {
      type: 'Major',
      name: 'Accounting',
      code: 'ACCNTCY05',
      cp: 144,
    },
    {
      type: 'Major',
      name: 'Actuarial Science',
      code: 'ACCNTCY05',
      cp: 144,
    },
    {
      type: 'Minor',
      name: 'Banking and Finance',
      code: 'ACCNTCY05',
      cp: 144,
    },
    {
      type: 'Major',
      name: 'Business (Business Education',
      code: 'ACCNTCY05',
      cp: 144,
    },
    {
      type: 'Major',
      name: 'Business (Business)',
      code: 'ACCNTCY05',
      cp: 144,
    },
    {
      type: 'Ext Major',
      name: 'Business (Commerce)',
      code: 'ACCNTCY05',
      cp: 144,
    },
    { type: 'Major', name: 'Business', code: 'ACCNTCY05', cp: 144 },
    {
      type: 'Major',
      name: 'Business Administration',
      code: 'ACCNTCY05',
      cp: 144,
    },
    {
      type: 'Major',
      name: 'Business Administration and Arts',
      code: 'ACCNTCY05',
      cp: 144,
    },
    {
      type: 'Major',
      name: 'Business Administration and Media',
      code: 'ACCNTCY05',
      cp: 144,
    },
    {
      type: 'Major',
      name: 'Business and Accounting',
      code: 'ACCNTCY05',
      cp: 144,
    },
    {
      type: 'Major',
      name: 'Business and Arts',
      code: 'ACCNTCY05',
      cp: 144,
    },
    {
      type: 'Major',
      name: 'Business (Commerce)',
      code: 'ACCNTCY05',
      cp: 144,
    },
    { type: 'Major', name: 'Business', code: 'ACCNTCY05', cp: 144 },
    {
      type: 'Major',
      name: 'Business Administration',
      code: 'ACCNTCY05',
      cp: 144,
    },
  ]

  const renderCourseList = courseList.map((item, i) => {
    return (
      <li key={i}>
        <Link
          to={`majors-and-minors/major/${item.name
            .toLowerCase()
            .replace(/ /g, '-')}`}>
          <div className={s.meta}>
            <span className={s.type}>{item.type}</span> • {item.code} •{' '}
            {item.cp} CP
          </div>
          <h3>{item.name}</h3>
        </Link>
      </li>
    )
  })

  // render

  return (
    <div className={s.page} mounted={mounted ? '' : null}>
      {/* header */}
      <h1>
        <IconMajorsMinors />
        <span>Majors and minors</span>
      </h1>

      {/* search */}
      <div className={s.search}>
        <h2>I am interested in</h2>

        <div className={s.searchBar}>
          <IconSearch />
          <input
            type='text'
            placeholder='Find any major, minor, or extended major across Monash'></input>
        </div>
      </div>

      {/* course list */}
      <div className={s.courseList}>
        <div className={s.tabs}>
          <span className={s.active}>All (42)</span>
          <span>Undergraduate</span>
          <span>Graduate</span>
          <span>Double Degrees</span>
          <span>Professional Development</span>
        </div>
        <ul>{renderCourseList}</ul>
      </div>
    </div>
  )
}

export default MajorsMinors
